import React, { useState, useEffect } from 'react';
import { Input, Form, Select, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

interface DataType {
  [key: string]: any;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: DataType;
  handleChangeCell: (record: DataType) => void;
  options?: { label: string; value: any }[];
  allOptions?: { label: string; value: any }[];
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleChangeCell,
  options,
  allOptions,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (editing) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
  }, [editing, form, dataIndex, record]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const handleChange = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleChangeCell({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable && !record?.isTemplate) {
    childNode = editing ? (
      <Form form={form} style={{ margin: 0 }} initialValues={{ [dataIndex]: record[dataIndex] }}>
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[{ required: true, message: `${title} is required.` }]}
        >
          {options || allOptions ? (
            <Select
              onBlur={handleChange}
              onChange={handleChange}
              dropdownStyle={{ width: 400 }}
              showSearch
              allowClear
            >
              {options && options.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
              {allOptions && allOptions.length > 1 && (
                <Select.OptGroup label="Weitere Zeitreihen">
                  {allOptions
                    .filter(option =>
                      !options?.some(opt => opt.value === option.value) 
                    ).map(option => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                </Select.OptGroup>
              )}
            </Select>
          ) : (
            <Input onPressEnter={handleChange} onBlur={handleChange} />
          )}
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24, cursor: 'pointer' }}
        onClick={toggleEdit}
      >
        {children}
        <EditOutlined style={{marginLeft: 4 }} />
      </div>
    );
  } else if (record?.isTemplate) {
    childNode = (
      <Tooltip
        title={
          options ? (
            <div>
              {options.map((option, index) => (
                <div key={index}>{option.label}</div>
              ))}
            </div>
          ) : ''
        }>
        <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }}>
          {children}
        </div>
      </Tooltip >
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
