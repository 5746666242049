import React, { useEffect, useState } from 'react';
import { Checkbox, Form, message, Table, Tooltip, Typography } from 'antd';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { apiUrl } from '../../common/url';
import DataCollSelection from './DataCollSelection';
import { ColumnsType } from 'antd/es/table';

interface DataType {
  [key: string]: any;
}

interface FixedColumn {
  Key: number;
  [key: string]: any;
}

interface CategoryColumn {
  name: string;
  description?: string;
}

interface CategoryData {
  title: string;
  description: string;
  columns: CategoryColumn[];
  data: DataType[];
}

interface BackendResponse {
  fixedColumns: FixedColumn[];
  categories: CategoryData[];
}

interface PortfolioDataProps {
  client: { id: string; name?: string, token: string, permissions: string[] };
}

const PortfolioData: React.FC<PortfolioDataProps> = ({ client }) => {
  const [dataCollYear, setDataCollYear] = useState<number>();
  const [visibleCategories, setVisibleCategories] = useState<string[]>([]);
  const [data, setData] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<DataType>>([]);
  const [totalWidth, setTotalWidth] = useState(0);

  const fetchDataColl = () => {
    axios.get(`${apiUrl}/b2zero/${client.id}/get_data_coll`, {
      params: {
        year: dataCollYear,
      },
      headers: { Authorization: `Bearer ${client.token}` }
    })
      .then(response => {
        const { fixedColumns, categories } = response.data as BackendResponse;
        const fixedColumnsDef: ColumnsType<DataType> = Object.keys(fixedColumns[0]).map((key, index) => ({
          title: key,
          dataIndex: key,
          key: `fixed-${key}-${index}`,
          fixed: 'left' as 'left',
          width: key === 'Key' ? 75 : 200,
        }));

        const categoryColumns: ColumnsType<DataType> = categories.map((category) => ({
          title: (
            <Tooltip title={category.description}>
              {category.title}
            </Tooltip>
          ),
          key: category.title,
          children: category.columns.map(col => ({
            title: (
              <Tooltip title={col.description}>
                {col.name}
              </Tooltip>
            ),
            dataIndex: col.name,
            key: `${category.title}-${col.name}`
          }))
        }));

        setColumns([...fixedColumnsDef, ...categoryColumns]);
        setData(fixedColumns.map((fixedCol, fixedColIndex) => ({
          ...fixedCol,
          key: `fixedCol-${fixedColIndex}`,
          ...categories.reduce((acc, category) => {
            const categoryData = category.data.find(data => data.Key === fixedCol.Key);
            return { ...acc, ...categoryData };
          }, {})
        })));
        // setVisibleCategories(categories.map(category => category.title)); // select all by default
        const visibleCategoriesNew = categories.length > 0 ? [categories[0].title] : [];
        setVisibleCategories(visibleCategoriesNew);

        setTotalWidth(calculateTotalWidth(visibleCategoriesNew))
      })
      .catch(error => {
        message.error('Fehler beim Abruf der Portfoliodaten: ' + error.message);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Abrufen der Portfoliodaten: ' + error.message);
          Sentry.captureException(error);
        });
      });
  }

  useEffect(() => {
    setTotalWidth(calculateTotalWidth(visibleCategories))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleCategories]);

  useEffect(() => {
    if (dataCollYear) {
      fetchDataColl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCollYear]);

  const filteredColumns = columns.filter((col) =>
    'children' in col ? visibleCategories.includes(col.key as string) : true
  );

  const calculateTotalWidth = (visibleCategories: string[]) => {
    const fixedColumnsWidth = columns
      .filter(col => !('children' in col))
      .reduce((acc, col) => {
        if (col.title === 'key') {
          return acc + 75;
        }
        return acc + 200;
      }, 0);

    const visibleCategoryColumnsWidth = columns
      .filter(col => 'children' in col && visibleCategories.includes(col.key as string))
      .reduce((acc, col) => {
        if ('children' in col) {
          return acc + (col.children?.length || 0) * 200;
        }
        return acc;
      }, 0);

    return fixedColumnsWidth + visibleCategoryColumnsWidth;
  };


  return (
    client === undefined ? <div>Please select a client.</div> :
      <>
        <h1>Portfoliodaten für {client.name}</h1>
        <DataCollSelection dataCollYear={dataCollYear} setDataCollYear={setDataCollYear} client={client} />

        <Table
          columns={filteredColumns}
          dataSource={data}
          rowKey="Key"
          bordered
          scroll={{ x: totalWidth }}
          title={() => <><Typography.Title level={3} style={{ margin: 0 }}>
            Portfoliodaten für Bezugsjahr {dataCollYear}
          </Typography.Title>
            <Form.Item label="Kategorien" style={{ marginBottom: 0 }}>
              <Checkbox.Group
                options={columns.filter(col => 'children' in col).map((col, index) => ({
                  label: col.title as React.ReactNode,
                  value: col.key as string,
                  key: `checkbox-${index}`
                }))}
                value={visibleCategories}
                onChange={(checkedValues) => {
                  setVisibleCategories(checkedValues as string[]);
                  setTotalWidth(calculateTotalWidth(checkedValues));
                }}
              />
            </Form.Item>
          </>}
        />
      </>
  );
};

export default PortfolioData;
